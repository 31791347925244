<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0" data-v-3639d1f2="">
                <h4 class="" data-v-3639d1f2="">Leave application / List</h4>

            </div>
            <!-- <div class="users-list-filter px-1">
                <div class="row border rounded py-2 mb-2 align-items-center">
                    <div class="col-12 col-md-4 mb-1 mb-sm-0">
                        <label for=""></label>
                        <input v-model="leaveApplicationsParams.appointment_id" class="form-control search-product-input-element" type="text" placeholder="Search a appointment..." />
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                        <label>Status</label>
                        <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="" :options="status" :close-on-select="true" placeholder="Select status" label="name" track-by="value" :show-labels="false" />
                    </div>
                    <div class="col-12 col-md-3 mb-1 mb-sm-0">
                        <label for=""></label>
                        <button @click="applyConsentFilter(null)" class="btn btn-primary btn-block glow users-list-clear mb-0">
                            Search
                        </button>
                    </div>
                </div>
            </div> -->
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive" ref="serviceListRef">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{ width: '20%' }">
                                            APPLICANT
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '15%' }">
                                            START DATE
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '15%' }">
                                            END DATE
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>

                                        <th class="position-relative" :style="{ width: '15%' }">
                                            REASON
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '15%' }">
                                            TYPE
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '15%' }">
                                            STATUS
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>

                                        <th class="position-relative" :style="{ width: '5%' }">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(leaveApplication, key) in leaveApplications" :key="key">
                                        <td>
                                            <div>
                                                <span class="font-weight-bold">Name:</span> {{(leaveApplication?.applicant?.first_name)?? ''}} {{(leaveApplication?.applicant?.last_name)?? ''}}
                                            </div>
                                            <div>
                                                <span class="font-weight-bold">Email: </span> {{(leaveApplication?.applicant?.email)?? ''}}
                                            </div>
                                            <div>
                                                <span class="font-weight-bold">Phone: </span>
                                                {{(leaveApplication?.applicant?.phone_number)?? ''}}
                                            </div>
                                        </td>
                                        <td>
                                            {{(leaveApplication?.start_at)?? ''}}

                                            <!-- <div v-if="appointmentConsent.status">
                                                <span v-if="appointmentConsent.status == 'Solved' " class="badge badge-light-success">
                                                    {{ appointmentConsent.status }}
                                                </span>
                                                <span v-else-if="appointmentConsent.status == 'Unsolved' " class="badge badge-light-danger">
                                                    {{ appointmentConsent.status }}
                                                </span>
                                                <span v-else-if="appointmentConsent.status == 'Agreed' " class="badge badge-light-primary">
                                                    {{ appointmentConsent.status }}
                                                </span>
                                            </div> -->
                                        </td>
                                        <td>
                                            {{(leaveApplication?.end_at)?? ''}}
                                            <!-- {{ appointmentConsent.created_at ? appointmentConsent.created_at : '' }} -->
                                        </td>
                                        <td>
                                            {{(leaveApplication?.reason)?? ''}}
                                        </td>
                                        <td>
                                            {{(leaveApplication?.type)?? ''}}
                                        </td>
                                        <td>
                                            {{(leaveApplication?.status)?? ''}}
                                        </td>

                                        <td>
                                            <div class="d-flex align-items-start justify-content-between">
                                                <button :disabled ="leaveApplication.status !== 'Awaiting'"  @click="openApplicationApproveAlertModal(leaveApplication.id)" class="btn btn-outline-success btn-sm mr-1"><i class='bx bx-check'></i></button>
                                                <button :disabled ="leaveApplication.status !== 'Awaiting'" @click="openApplicationDeclineAlertModal(leaveApplication.id)" class="btn btn-outline-danger btn-sm "><i class='bx bx-x'></i></button>
                                                <!-- <span @click="getSingleAppointment(appointment.id)" class="cursor-pointer view-edit-delete-icon" :style="{height:'18px'}">
                                                    <i class='bx bx-show'></i>
                                                </span> -->

                                                <!-- <div class="dropdown">
                                                    <span type="button" class="px-0 py-0 view-edit-delete-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="bx bx-dots-vertical-rounded h-100"></i>
                                                    </span>
                                                    <div class="dropdown-menu">

                                                        <button class="dropdown-item" @click="openAppointmentConsentDeleteModal(appointmentConsent.id)">
                                                            Delete
                                                        </button>

                                                        <button class="dropdown-item" @click="openAppointmentCloserNoteModal(appointmentConsent.id)">
                                                            View notes
                                                        </button>
                                                    </div>
                                                </div> -->

                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <div data-toggle="modal" data-target="#applicationApproveAlertModal"></div>
                            <div data-toggle="modal" data-target="#applicationDeclineAlertModal"></div>

                            <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyConsentFilter" />
                            <AppointmentCloserNote :appointmentConsent="appointmentConsent" />

                            <ApplicationApproveAlertModal :model-id="1" cancel-title="NO" ok-title="YES" model-name="approve" modal-name="applicationApproveAlertModal" header-class="success" ok-only @confirmActionModel="approveApplication"><template v-slot:modal-header>Do you want to accept this leave application ? </template></ApplicationApproveAlertModal>

                            <ApplicationDeclineAlertModal :model-id="1" cancel-title="NO" ok-title="YES" model-name="decline" modal-name="applicationDeclineAlertModal" header-class="warning" ok-only @confirmActionModel="declineApplication"><template v-slot:modal-header> Do you want to reject this leave application ?</template></ApplicationDeclineAlertModal>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <DeleteAlertModal /> -->
        </section>
    </template>
</AppLayout>
</template>

<script>
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ApplicationApproveAlertModal from '@/components/backEnd/modal/ConfirmAlertModal';
import ApplicationDeclineAlertModal from '@/components/backEnd/modal/ConfirmAlertModal';
// import DeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal.vue'
import {
    mapActions,
    mapGetters
} from "vuex";
import AppointmentCloserNote from "@/views/backEnd/appointments/appointmentClosers/includes/AppointmentCloserNote";
export default {
    name: "ServiceList",
    mixins: [ShowToastMessage, Loader],
    components: {
        AppointmentCloserNote,
        ApplicationApproveAlertModal,
        ApplicationDeclineAlertModal,
        AppLayout,
        // DeleteAlertModal,
        ListPagination,
    },

    /** 0 === Appointment Closing Consent **/
    data() {

        return {
            leaveApplicationsParams: {

                with_relation: ["approver", "applicant"],
                paginate: 1,
                page: "",
                pagination: "",
                order_by_id: "DESC"
            },
            leaveApplication: {},
            selectedStatus: {
                value: "",
                name: "Any",
            },
            getSettingsParams: {
                type: ["default"],
                key: [
                    "default_pagination",

                ],
            },

        };
    },
    computed: {
        ...mapGetters({
            leaveApplications: "appLeaveApplications/leaveApplications",
            paginateLinks: "appAppointmentConsents/paginateLinks",
        }),
    },
    methods: {
        ...mapActions({
            getLeaveApplications: "appLeaveApplications/getLeaveApplications",
            putLeaveApplication: "appLeaveApplications/putLeaveApplication",
            getSettings: "appSettings/getSettings",

        }),
        openApplicationApproveAlertModal(id) {
            this.findLeaveApplication(id);
            document.querySelector('[data-target="#applicationApproveAlertModal"]').click();
        },
        openApplicationDeclineAlertModal(id) {
            this.findLeaveApplication(id);
            document.querySelector('[data-target="#applicationDeclineAlertModal"]').click();
        },

        async approveApplication(confirmActionModel) {
            if (confirmActionModel.status === true) {
                let dataObj = {
                    id: this.leaveApplication.id,
                    data: {
                        status: 1,
                        approver_id: this.$store.getters["appAuthLogin/authUser"].id,
                    },
                };
                const response = await this.putLeaveApplication(dataObj);
                if (response.message) {

                    this.showToastMessage(response);
                }
                if (response.status === 200) {
                    this.loader(false);
                    this.showToastMessage({
                        type: 'success',
                        message: 'Application Successfully Accepted '
                    });
                    await this.getLeaveApplicationList(this.leaveApplicationsParams);
                    return;
                }
                this.loader(false);
                this.showToastMessage({
                    type: 'error',
                    message: ' Something went wrong  '
                });
                return;

            }
            console.log(confirmActionModel, "approve");
        },
        async declineApplication(confirmActionModel) {
            if (confirmActionModel.status === true) {
                let dataObj = {
                    id: this.leaveApplication.id,
                    data: {
                        status: 2,
                        approver_id: this.$store.getters["appAuthLogin/authUser"].id,
                    },
                };
                const response = await this.putLeaveApplication(dataObj);
                if (response.message) {

                    this.showToastMessage(response);
                }
                if (response.status === 200) {
                    this.loader(false);
                    this.showToastMessage({
                        type: 'success',
                        message: ' Application Successfully Rejected '
                    });
                    await this.getLeaveApplicationList(this.leaveApplicationsParams);
                    return;
                }
                this.loader(false);
                this.showToastMessage({
                    type: 'error',
                    message: ' Something went wrong  '
                });
                return;
            }
            console.log(confirmActionModel, " decline ");
        },
        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },

        findLeaveApplication(id) {
            this.leaveApplication = this.leaveApplications.find((leaveApplication) => leaveApplication.id === id);

        },

    

        async getLeaveApplicationList(leaveApplicationsParams) {
            this.loader(true);
            await this.getLeaveApplications(leaveApplicationsParams).then((response) => {
                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
            this.loader(false);
        },
        // async applyConsentFilter(pageNumber) {

        //     this.leaveApplicationsParams.page = pageNumber;
        //     await this.getLeaveApplicationList(this.leaveApplicationsParams);
        // },

    },

    async mounted() {
        await this.getSettingList(this.getSettingsParams);
        await this.getLeaveApplicationList(this.leaveApplicationsParams);
    },

    beforeMount() {
        // this.loader(true);
        // this.resetAppointmentConsents();
    },

    watch: {

    },

};
</script>

<style scoped>
</style>
