<template>
<div class="modal fade" id="AppointmentCloserNote" tabindex="-1" role="dialog" aria-labelledby="AppointmentCloserNote" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Notes</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-12 col-md-12">
                            <p class="font-small-3">{{remark}}</p>
                        </div>
                       
                    </div>

                </div>

            </div>
             <div class="modal-footer border-0 pt-0">
            <button type="button" class="btn btn-primary"  data-dismiss="modal">
                <i class="bx bx-x d-block d-sm-none"></i>
                <span class="d-none d-sm-block">Close</span>
            </button>
        </div>
        </div>
       
    </div>
</div>

</template>

<script>
export default {
    name: "AppointmentCloserNote",
    props: ['appointmentConsent'],
    data() {
        return {
            remark: ""
        };
    },
    watch: {
        appointmentConsent(currentAppointmentConsent) {
            this.remark = currentAppointmentConsent.remark ? currentAppointmentConsent.remark : '';
        }
    },
    methods: {

    },

};
</script>

<style scoped>
.rounded-avatar {
    overflow: hidden;
    width: 60px;
    border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #7698d9 !important;
    border-bottom: none !important;
    border-left: none !important;
    /* border-top: none  !important; */
}
</style>
